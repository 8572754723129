import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module';
import {HttpClientModule} from '@angular/common/http';
import {ApiModule} from './core/api.module';
import { AuthenticationComponent } from './feature/authentication/authentication.component';
import {FormsModule} from '@angular/forms';
import { ToolbarComponent } from './shared/toolbar/toolbar.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { ConfigurationComponent } from './feature/configuration/configuration.component';
import { UsersComponent } from './feature/users/users.component';
import { ReportedComponent } from './feature/reported/reported.component';
import { SourcesComponent } from './feature/sources/sources.component';
import {MatTableModule} from '@angular/material/table';
import { DetailUserComponent } from './feature/users/detail-user/detail-user.component';
import { DisableUserComponent } from './feature/users/disable-user/disable-user.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { EnableUserComponent } from './feature/users/enable-user/enable-user.component';
import { LocationComponent } from './feature/configuration/location/location.component';
import { PracticeComponent } from './feature/configuration/practice/practice.component';
import { LocationEditComponent } from './feature/configuration/location/location-edit/location-edit.component';
import { LocationDeleteComponent } from './feature/configuration/location/location-delete/location-delete.component';
import { PracticeEditComponent } from './feature/configuration/practice/practice-edit/practice-edit.component';
import { PracticeDeleteComponent } from './feature/configuration/practice/practice-delete/practice-delete.component';
import { ParameterComponent } from './feature/configuration/parameter/parameter.component';
import { ParameterEditComponent } from './feature/configuration/parameter/parameter-edit/parameter-edit.component';
import { ReportedExperienceComponent } from './feature/reported/reported-experience/reported-experience.component';
import { ReportedUserComponent } from './feature/reported/reported-user/reported-user.component';
import { DisableExperienceComponent } from './feature/reported/reported-experience/disable-experience/disable-experience.component';
import { EnableExperienceComponent } from './feature/reported/reported-experience/enable-experience/enable-experience.component';

@NgModule({
  declarations: [
    AppComponent,
    AuthenticationComponent,
    ToolbarComponent,
    ConfigurationComponent,
    UsersComponent,
    ReportedComponent,
    SourcesComponent,
    DetailUserComponent,
    DisableUserComponent,
    EnableUserComponent,
    LocationComponent,
    PracticeComponent,
    LocationEditComponent,
    LocationDeleteComponent,
    PracticeEditComponent,
    PracticeDeleteComponent,
    ParameterComponent,
    ParameterEditComponent,
    ReportedExperienceComponent,
    ReportedUserComponent,
    DisableExperienceComponent,
    EnableExperienceComponent
  ],
  imports: [
    ApiModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    MatToolbarModule,
    MatTableModule,
    MatFormFieldModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
