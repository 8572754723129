import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import {BoUser} from '../../models/bo-user';

@Injectable({
  providedIn: 'root'
})
export class BoUserService {

  private users = '/account';

  private user: BoUser = {
    email: '',
    firstname: '',
    id: 0,
    lastname: '',
  };

  private userSubject = new BehaviorSubject<BoUser>(this.user);
  userData = this.userSubject.asObservable();

  constructor(private httpClient: HttpClient) { }

  get(): Observable<BoUser> {
    return this.httpClient
      .get(environment.apiUrl + environment.apiVersion + this.users)
      .pipe(map((data) => data as BoUser), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  setUser(user: BoUser): void {
    this.userSubject.next(user);
  }

}
