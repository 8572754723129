import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportedService {

  private reportedURL = '/reported';
  private reportedUsersURL = '/users';
  private reportedExperiencesURL = '/experiences';

  constructor(private httpClient: HttpClient) { }

  getUsersReported(params?: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + environment.apiVersion + this.reportedURL + this.reportedUsersURL, { params })
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  getExperiencesReported(params?: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + environment.apiVersion + this.reportedURL + this.reportedExperiencesURL, { params })
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  updateExperiencesReported(params: any, id: number): Observable<any> {
    return this.httpClient.put(environment.apiUrl + environment.apiVersion + this.reportedURL + this.reportedExperiencesURL + '/' + id, params);
  }

}
