import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ReportedService} from '../../../core/api/reported/reported.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {EnableExperienceComponent} from './enable-experience/enable-experience.component';
import {DisableExperienceComponent} from './disable-experience/disable-experience.component';

@Component({
  selector: 'app-reported-experience',
  templateUrl: './reported-experience.component.html',
  styleUrls: ['./reported-experience.component.scss']
})
export class ReportedExperienceComponent implements OnInit {

  displayedColumns: string[] = ['id', 'title', 'actions'];
  experiences: Array<any> = [];
  dataSource = new MatTableDataSource(this.experiences);
  pagination: any;

  constructor(private reportedService: ReportedService,
              private snackBar: MatSnackBar,
              private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getExperiences(1);
  }

  getExperiences(pagination: any): void {
    this.reportedService.getExperiencesReported({page: pagination}).subscribe(dataExperiences => {
      this.pagination = dataExperiences.page;
      this.experiences = dataExperiences.experiences;
      this.dataSource = new MatTableDataSource(this.experiences);
    });
  }

  disable(id: number): void {
    const experience = this.experiences.find((e) => e.id === id);
    const dialogRef = this.matDialog.open(DisableExperienceComponent, {
      width: '600px',
      height: '220px',
      data: {
        title: experience.title
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body = {
          is_disabled: true,
        };
        this.reportedService.updateExperiencesReported(body, id).subscribe(data => {
          this.snackBar.open('L\'expérience a été désactivée.');
          this.getExperiences(1);
        });
      }
    });
  }

  enable(id: number): void {
    const experience = this.experiences.find((e) => e.id === id);
    const dialogRef = this.matDialog.open(EnableExperienceComponent, {
      width: '600px',
      height: '220px',
      data: {
        title: experience.title
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const body = {
          is_disabled: false,
        };
        this.reportedService.updateExperiencesReported(body, id).subscribe(data => {
          this.snackBar.open('L\'expérience a été réactivée.');
          this.getExperiences(1);
        });
      }
    });
  }

  previous(): void {
    this.getExperiences(this.pagination.prev);
  }

  next(): void {
    this.getExperiences(this.pagination.next);
  }

}
