import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private allConfigurationsURL = '/configurations';
  private configurationURL = '/configuration';
  private criteriaLocationURL = '/locations';
  private criteriaPracticeURL = '/practices';

  constructor(private httpClient: HttpClient) { }

  getCriteriaLocation(params?: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaLocationURL, { params })
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  deleteCriteriaLocation(id: number): Observable<any> {
    return this.httpClient
      .delete(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaLocationURL + '/' + id);
  }

  createCriteriaLocation(params: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaLocationURL, params)
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  updateCriteriaLocation(id: number, params: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaLocationURL + '/' + id, params)
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  getCriteriaPractice(params?: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaPracticeURL, { params })
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  deleteCriteriaPractice(id: number): Observable<any> {
    return this.httpClient
      .delete(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaPracticeURL + '/' + id);
  }

  createCriteriaPractice(params: any): Observable<any> {
    return this.httpClient
      .post(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaPracticeURL, params)
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  updateCriteriaPractice(id: number, params: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + environment.apiVersion + this.configurationURL + this.criteriaPracticeURL + '/' + id, params)
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  getConfigurations(params?: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + environment.apiVersion + this.allConfigurationsURL, { params })
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  updateConfiguration(id: number, params: any): Observable<any> {
    return this.httpClient
      .put(environment.apiUrl + environment.apiVersion + this.allConfigurationsURL + '/' + id, params)
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }
}
