<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 mt-30">
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>ID</th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef>Titre</th>
    <td mat-cell *matCellDef="let element"> {{element.title}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let element">
      <a class="icon" (click)="disable(element.id)" *ngIf="!element.is_disabled"><img src="/assets/moins.svg"></a>
      <a class="icon" (click)="enable(element.id)" *ngIf="element.is_disabled"><img src="/assets/plus.svg"></a>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<div *ngIf="experiences.length > 0" class="row mt-30" style="justify-content: center;">
  <div [class]="pagination?.prev != null ? 'button-pagination' : 'button-pagination disabled'" (click)="previous()"><span>Précédent</span></div>
  <div class="ml-20" [class]="pagination?.next != null ? 'button-pagination' : 'button-pagination disabled'" (click)="next()"><span>Suivant</span></div>
</div>
