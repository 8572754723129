import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private usersURL = '/users';

  constructor(private httpClient: HttpClient) { }

  getAllUsers(params?: any): Observable<any> {
    return this.httpClient
      .get(environment.apiUrl + environment.apiVersion + this.usersURL, { params })
      .pipe(map((data) => data as any), catchError((err: any) => err.code === 404 ? [] : throwError(err)));
  }

  updateUser(params: any, id: number): Observable<any> {
    return this.httpClient.put(environment.apiUrl + environment.apiVersion + this.usersURL + '/' + id, params);
  }

}
